import React from 'react'
import { graphql } from 'gatsby'

import Button from '../components/Button'
import getPath from '../utils/getPath'

const ArticleEncart = ({
  titre,
  contenu,
  image,
  picto,
  bouton,
  texteDuBouton,
}) => {
  return (
    <div
      className={`container relative mx-auto p-10 rounded grid grid-cols-3 gap-3 ${
        !!image ? 'shadow-card' : 'border'
      }`}
    >
      <div className={`${!!image ? 'col-span-2' : 'col-span-3'}`}>
        <div className="flex items-center mb-6">
          {!!picto && (
            <img src={picto.url} className="mr-4" width="60px" height="60px" />
          )}
          <h3 className="text-xl font-bold">{titre}</h3>
        </div>
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{ __html: contenu }}
        ></div>
        <Button variant="green" to={getPath(bouton)}>
          {texteDuBouton}
        </Button>
      </div>
      {!!image && (
        <div
          className="col-span-1 bg-center bg-no-repeat bg-contain"
          style={{ backgroundImage: `url(${image.url})` }}
        />
      )}
    </div>
  )
}

export const articleEncartFragment = graphql`
  fragment DatoCmsBlocArticleEncartFragment on DatoCmsBlocArticleEncart {
    model {
      apiKey
    }
    id
    originalId
    titre
    contenu
    image {
      alt
      url
    }
    picto {
      alt
      url
    }
    bouton {
      ... on DatoCmsPage {
        ...DatoCmsPageLinkFragment
      }
      ... on DatoCmsArticle {
        ...DatoCmsArticleLinkFragment
      }
    }
    texteDuBouton
  }
`

export default ArticleEncart
