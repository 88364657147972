import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ArticleImage = ({ image }) => {
  if (!image) {
    return ''
  }
  if (image.gatsbyImageData) {
    return (
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.alt}
        className="mb-6"
      />
    )
  }
  return <img className="w-full h-auto mb-6" alt={image.alt} src={image.url} />
}

export const ArticleImageFragment = graphql`
  fragment DatoCmsBlocArticleImageFragment on DatoCmsBlocArticleImage {
    model {
      apiKey
    }
    id
    originalId
    image {
      url
      mimeType
      alt
      gatsbyImageData
    }
  }
`

export default ArticleImage
