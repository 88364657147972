import React from 'react'
import slugify from 'slugify'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { render } from 'datocms-structured-text-to-plain-text'

import ArticleEncart from '../blocks/ArticleEncart'

import StructuredText from '../components/StructuredText'
import FollowingSidebar from '../components/FollowingSidebar'
import Link from '../components/Link'

import Layout from '../partials/Layout'

import getPath from '../utils/getPath'
import ArticleImage from '../blocks/ArticleImage'

const Article = ({ data }) => {
  const titres = data.datoCmsArticle.contenu.value
    ? data.datoCmsArticle.contenu.value.document.children
        .filter((node) => node.type === 'heading' && node.level === 2)
        .map((node) => {
          const titre = render(node)
          return {
            titre,
            id: slugify(titre, {
              strict: true,
              lower: true,
              locale: 'fr',
            }),
          }
        })
    : []
  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsArticle.seoMetaTags} />
      <div className="container mx-auto my-3 text-sm text-gray-300">
        <Link to="/">Accueil</Link>
        <span className="ml-2">{'>'}</span>
        <Link
          className="ml-2"
          to={`/${
            data.datoCmsArticle.categorie.slug === 'articles'
              ? 'articles/'
              : `${data.datoCmsArticle.categorie.slug}/${data.datoCmsArticle.moyenAssure.slug}/`
          }`}
        >
          {data.datoCmsArticle.categorie.titre}
        </Link>
        <span className="ml-2">{'>'}</span>
        <Link className="ml-2" to={getPath(data.datoCmsArticle)}>
          {data.datoCmsArticle.titre}
        </Link>
      </div>
      <FollowingSidebar titres={titres}>
        <h1 className="mb-12 text-5xl font-bold">
          {data.datoCmsArticle.titre}
        </h1>
        <p className="mb-12 text-2xl">{data.datoCmsArticle.chapo}</p>
        {!!data.datoCmsArticle.image && (
          <GatsbyImage
            className="mb-12 rounded"
            image={data.datoCmsArticle.image.gatsbyImageData}
            alt={data.datoCmsArticle.image.alt}
          />
        )}
        {data.datoCmsArticle.contenu.value && (
          <StructuredText
            data={data.datoCmsArticle.contenu}
            renderBlock={({ record }) => {
              switch (record.model.apiKey) {
                case 'bloc_article_encart':
                  return <ArticleEncart {...record} />
                case 'bloc_article_image':
                  return <ArticleImage {...record} />
                default:
                  return null
              }
            }}
          />
        )}
      </FollowingSidebar>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($id: String) {
    datoCmsArticle(id: { eq: $id }) {
      id
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      titre
      categorie {
        titre
        slug
      }
      moyenAssure {
        slug
      }
      chapo
      image {
        alt
        gatsbyImageData(layout: FULL_WIDTH)
      }
      contenu {
        value
        links {
          __typename
          ... on DatoCmsPage {
            id
            originalId
            slug
            titre
            typeDePage
            model {
              apiKey
            }
          }
          ... on DatoCmsArticle {
            id
            slug
            titre
            originalId
            categorie {
              slug
            }
            model {
              apiKey
            }
          }
        }
        blocks {
          __typename
          ... on DatoCmsBlocArticleEncart {
            ...DatoCmsBlocArticleEncartFragment
          }
          ... on DatoCmsBlocArticleImage {
            ...DatoCmsBlocArticleImageFragment
          }
        }
      }
    }
  }
`
